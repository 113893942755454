import { LocalBusinessJsonLd, NextSeo } from 'next-seo'
import React from 'react'
import { useShopContext } from '../../context'
import { getProfileIndexFromLanguage } from '../getProfile'
import { getCanonicalUrl } from './getCanonicalUrl'

interface DefaultMetaTagsProps {
  shopSettingsData: Record<string, any>
  locale: string
  locales: string[]
  defaultLocale: string
  initialI18nStore: Record<string, any>
  pageName?: String
}

export const defaultMetaTags = ({
  shopSettingsData,
  locale,
  locales,
  defaultLocale,
  initialI18nStore,
  pageName,
}: DefaultMetaTagsProps) => {
  const shopProfileIndex = getProfileIndexFromLanguage(
    shopSettingsData.data.findFirstShopSetting?.shop_profiles,
    locale,
  )
  const shopSettings = shopSettingsData.data.findFirstShopSetting

  //* ============================================================
  //* ====================== PROFILES ============================
  //* ============================================================
  //!if the index passed to the profiles array is null, the profile will be undefined
  const shopProfile =
    shopSettingsData.data.findFirstShopSetting?.shop_profiles[shopProfileIndex]

  const domainToRefer = getCanonicalUrl(locale, shopSettings?.enabled_languages)
  const currentDomain = getCanonicalUrl(
    defaultLocale,
    shopSettings?.enabled_languages,
  )
  let titleTemplate = ''
  let descriptionTemplate = ''

  // TODO fix this from the context ====
  // const { shopPhoneNumber } = useShopContext()
  const shopPhoneNumber = null

  //* =================================
  //* =========== TEMPLATES ===========
  //* =================================
  //TODO: IMPORTANT change the templates in the switch, now it's in english for all
  // switch (locale) {
  //   case 'lt':
  //     //set title
  //     titleTemplate = `FURNIBAY - Baldai Internetu ir Nemokamas Pristatymas 🚀`
  //     //set description
  //     descriptionTemplate = `Atraskite mūsų platų baldų ir namų apyvokos reikmenų asortimentą, derinantį kokybę ir prieinamą kainą. Nuo sofų iki stalų ir apšvietimo – apsipirkite dabar ${currentDomain}`
  //     break
  //   case 'et':
  //     //set title
  //     titleTemplate = `FURNIBAY - Mööbel Internetis ja tasuta kohaletoimetamine 🚀`

  //     //set description
  //     descriptionTemplate = `Avastage meie laias valikus mööblit ja kodutarbeid, mis ühendavad kvaliteedi ja taskukohasuse. Ostke kohe alates diivanist kuni laudade ja valgustiteni ${currentDomain}`
  //     break
  //   case 'lv':
  //     //set title
  //     titleTemplate = `FURNIBAY - Mēbeles tiešsaistē un bezmaksas piegāde 🚀`

  //     //set description
  //     descriptionTemplate = `Atklājiet mūsu plašo mēbeļu un mājas piederumu klāstu, kas apvieno kvalitāti un pieejamību. No dīvāniem līdz galdiem un apgaismojumam — iepērcieties jau tagad ${currentDomain}`
  //     break
  //   case 'en':
  //     //set title
  //     titleTemplate = `FURNIBAY - Furniture Online and Free Delivery 🚀`

  //     //set description
  //     descriptionTemplate = `Discover our wide range of furniture and homeware combining quality and affordability. From sofas to tables and lighting, shop now on ${currentDomain}`
  //     break
  //   case 'ru':
  //     //set title
  //     titleTemplate = `FURNIBAY - Мебель онлайн и бесплатная доставка 🚀`

  //     //set description
  //     descriptionTemplate = `Откройте для себя наш широкий ассортимент мебели и товаров для дома, сочетающий в себе качество и доступность. От диванов до столов и освещения - делайте покупки прямо сейчас ${currentDomain}`
  //     break
  //   default:
  //     console.log(
  //       'ADD THE NEW LOCALE IN THE DEFAULT METATAGS UTILITY! We hit the default case in the creation of the category template for meta_description. This could mean that you forgot to add a new locale to the switch',
  //     )
  //     break
  // }

  // Meta images can only be JPEG OR PNG
  const image = shopSettingsData.data.findFirstShopSetting?.default_meta_image
    ? shopSettingsData.data.findFirstShopSetting?.default_meta_image.src
    : ``
  // ---- Turning this off - due to query of shop settings being too big...
  const default_manufacturer_phone =
    shopSettingsData.data.findFirstShopSetting?.default_manufacturer
      ?.manufacturer_contacts?.phone_number ?? shopPhoneNumber
  // const latitude =
  //   shopSettingsData.data.findFirstShopSetting?.default_manufacturer
  //     ?.manufacturer_contacts.coordinates?.lat;
  // const longitude =
  //   shopSettingsData.data.findFirstShopSetting?.default_manufacturer
  //     ?.manufacturer_contacts.coordinates?.lng;
  const shopName = shopProfile?.name
  // const description =
  //   shopProfile?.meta_information.meta_description ?? descriptionTemplate;
  const openingHours = initialI18nStore[locale].common.openingHours
  const address = initialI18nStore[locale].common.address

  const title =
    !shopProfile ||
      shopProfile?.meta_information?.meta_title === '' ||
      shopProfile?.meta_information?.meta_title === null
      ? titleTemplate
      : shopProfile?.meta_information?.meta_title
  const description =
    !shopProfile ||
      shopProfile?.meta_information?.meta_description === '' ||
      shopProfile?.meta_information?.meta_description === null
      ? descriptionTemplate
      : shopProfile?.meta_information?.meta_description
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={domainToRefer}
        // languageAlternates={languageAlternates}
        openGraph={{
          url: currentDomain,
          title: shopName,
          description,
          images: [{ url: image }],
          site_name: shopName,
          type: 'website',
        }}
      // twitter={{
      //   handle: "@furnibay",
      //   site: "@furnibay",
      //   cardType: "summary_large_image",
      // }}
      />
      <LocalBusinessJsonLd
        type="FurnitureStore"
        id={currentDomain}
        name={shopProfile?.name}
        description={
          shopProfile?.meta_information.meta_description ??
          `Discover our wide range of furniture and homeware combining quality and affordability. From sofas to tables and lighting, shop now on ${currentDomain}`
        }
        url={currentDomain}
        telephone={default_manufacturer_phone}
        //TODO: in the schema the company address is a String, change it to Address type and come fix this
        address={{
          ...address,
        }}
        // geo={{
        //   latitude,
        //   longitude,
        // }}
        images={[image]}
        openingHours={[...openingHours]}
      />
    </>
  )
}
